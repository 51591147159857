const getApiEndpoint = path => process.env.API_URL + 'v1' + path;

const requestApi = async ({
  method,
  path,
  payload,
  responseCallback,
  cache = false,
}) => {
  const url = getApiEndpoint(path);
  const request = { method };
  if (payload) {
    request.headers = { 'Content-Type': 'application/json' };
    request.body = JSON.stringify(payload);
  }

  if (cache) {
    cacheThenNetworkFetch(url, request, responseCallback);
  } else {
    const response = await fetch(url, request);
    const json = await response.json();
    responseCallback(json);
  }
};

export const getConsumptions = responseCallback => requestApi({
  method: 'GET',
  path: '/',
  responseCallback,
  cache: true,
});

export const pushConsume = (name, responseCallback) => requestApi({
  method: 'POST',
  path: '/consume',
  payload: { name },
  responseCallback,
});

const cacheThenNetworkFetch = async (url, request, responseCallback) => {
  let networkDataReceived = false;

  fetch(url, request).then(async response => {
    const json = await response.json();
    networkDataReceived = true;
    responseCallback(json);
  }).catch(() => {
    console.log('Please connect to the internet!');
  });

  const response = await caches.match(url);
  if (response) {
    const json = await response.json();
    if (!networkDataReceived) {
      responseCallback(json);
    }
  }
};
