const KEY_PREFIX = 'prop:';

class PropRegistry {
  static get initialRegistry () {
    const initialRegistry = {};
    this.predefinedProps.forEach(
      ([name, value]) => initialRegistry[this.buildKeyFromName(name)] = value
    );
    return initialRegistry;
  }

  static buildKeyFromName(name) {
    return KEY_PREFIX + name;
  }

  constructor() {
    this.currentIndex = 0;
    this.registry = this.constructor.initialRegistry;
  }

  push(value) {
    const key = this.buildKeyFromValue(value);
    this.registry[key] = value;
    return key;
  }

  get(key) {
    return this.registry[key];
  }

  delete(key) {
    delete this.registry[key];
  }

  hasKey(key) {
    return key in this.registry;
  }

  buildKeyFromValue(value) {
    const predefinedProp = this.constructor.predefinedProps.find(
      ([, valueN]) => value === valueN
    );
    const name = predefinedProp ? predefinedProp[0] : this.generateName();
    return this.constructor.buildKeyFromName(name);
  }

  generateName() {
    return this.currentIndex++;
  }
}

PropRegistry.predefinedProps = [
  ['true', true],
  ['false', false],
];

const propRegistry = new PropRegistry();

export const deregisterProp = key => propRegistry.delete(key);
export const getPropFromRegistry = key => propRegistry.get(key);
export const registerProp = value => propRegistry.push(value);
export const registerProps = values => values.map(value => registerProp(value));
export const isRegistered = key => propRegistry.hasKey(key);
