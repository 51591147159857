import { WebComponent } from '../lib/web-components';
import { pushConsume } from '../requests';

export default class AddDrinker extends WebComponent {
  static get tagName() {
    return 'add-drinker';
  }

  renderCallback() {
    this.querySelector('form').addEventListener('submit', event => {
      event.preventDefault();
      const formElement = event.target;
      const drinkerName = formElement.elements['drinkerName'].value;
      pushConsume(drinkerName, this.propset.onAddDrinker);
    });
  }
}

AddDrinker.define();
