import {
  deregisterProp,
  getPropFromRegistry,
  isRegistered,
  registerProp,
} from './PropRegistry';

const PropSet = dataset => new Proxy(dataset, {
  get: (dataset, name) => {
    if (!(name in dataset)) {
      return undefined;
    }

    const data = dataset[name];
    return isRegistered(data) ? getPropFromRegistry(data) : data;
  },
  set: (dataset, prop, value) => {
    if (prop in dataset) {
      deregisterProp(dataset.prop);
    }

    dataset[prop] = registerProp(value);
    return true;
  },
});

export default PropSet;
