import { WebComponent, registerProps, registerProp } from '../lib/web-components';
import { getConsumptions } from '../requests';

export default class ConsumptionList extends WebComponent {
  static get tagName() {
    return 'consumption-list';
  }

  static get observedAttributes() {
    return ['data-consumptions', 'data-loading'];
  }

  async connectedCallback() {
    this.propset.consumptions = [];
    this.propset.loading = true;

    const updateCallback = consumptions => {
      this.propset.consumptions = consumptions;
      this.propset.loading = false;
    };
    getConsumptions(updateCallback);
  }

  get view() {
    return {
      ...this.propset,
      consumptions: registerProps(this.propset.consumptions),
      onAddDrinker: registerProp(this.onAddDrinker.bind(this)),
    };
  }

  onAddDrinker(drinker) {
    this.propset.consumptions = [...this.propset.consumptions, drinker];
  }
}

ConsumptionList.define();
