import { pushConsume } from '../requests';
import { WebComponent } from '../lib/web-components';

export default class ConsumptionItem extends WebComponent {
  static get tagName() {
    return 'consumption-item';
  }

  static get observedAttributes() {
    return ['data-consumption', 'selected'];
  }

  connectedCallback() {
    this.addEventListener('click', () => {
      this.toggleAttribute('selected');
      document.querySelectorAll('consumption-item').forEach(
        el => el !== this && el.removeAttribute('selected')
      );
    });

    this.render();
  }

  renderCallback() {
    this.querySelector('button').addEventListener('click', async event => {
      const { consumption } = this.propset;
      const drinks = (consumption.drinks + 1) || 1;
      this.propset.consumption = { ...consumption, drinks };

      pushConsume(consumption.name, updatedConsumption => {
        this.propset.consumption = updatedConsumption;
      });
      event.stopPropagation();
    });
  }

  get view() {
    const { consumption } = this.propset;

    const drinksArray = [];
    for (let i = 0; i < consumption.drinks; i++) {
      drinksArray.push('|');
    }

    return {
      ...this.attributesObject,
      ...consumption,
      drinksArray,
      initial: consumption.name[0],
      drinksDescription: consumption.drinks === 1 ? 'Stroke' : 'Strokes',
    };
  }
}

ConsumptionItem.define();
