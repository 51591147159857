import Mustache from 'mustache';

import { PropSet } from './props';

export default class WebComponent extends HTMLElement {
  static define() {
    window.customElements.define(this.tagName, this);
  }

  static get tagName() {
    throw Error(
      `The WebComponent ${this.name} has no tagName specified.
       Please add a static getter tagName to its class.`
    );
  }

  static get template() {
    return document.getElementById(this.templateId);
  }

  static get templateId() {
    return this.tagName;
  }

  constructor() {
    super();
    this.propset = PropSet(this.dataset);
  }

  attributeChangedCallback() {
    this.render();
  }

  connectedCallback() {
    this.render();
  }

  render() {
    const { template } = this.constructor;
    if (template) {
      let innerHTML = template.innerHTML;
      if (template.attributes.mustache) {
        const view = this.view;
        if (process.env.NODE_ENV === 'development') {
          /* eslint-disable-next-line no-debugger */
          view.debugger = () => { debugger; };
        }
        innerHTML = Mustache.render(innerHTML, view);
      }
      this.innerHTML = innerHTML;
    }
    this.renderCallback();
  }

  renderCallback() {
    return;
  }

  addAttributes(object) {
    Object.entries(object).forEach(([key, value]) => this.setAttribute(key, value));
  }

  toggleAttribute(attribute) {
    if (this.attributes[attribute]) {
      this.removeAttribute(attribute);
    } else {
      this.setAttribute(attribute, attribute);
    }
  }

  get attributesObject() {
    return Object.values(this.attributes).reduce((obj, attr) => ({ ...obj, [attr.name]: attr.value }), {});
  }

  get view() {
    return this.attributesObject;
  }
}
