import { WebComponent } from '../lib/web-components';

export default class CervezaImprint extends WebComponent {
  constructor() {
    super();

    this.showImprint = false;
  }

  static get tagName() {
    return 'cerveza-imprint';
  }

  renderCallback() {
    this.querySelector('a').addEventListener('click', () => {
      this.showImprint = !this.showImprint;
      this.render();
    });
  }

  get view() {
    return {
      showImprint: this.showImprint,
    };
  }
}

CervezaImprint.define();
